<script setup lang="ts">
import { pascalCase } from 'scule';
import { useSchemaOrg, defineBreadcrumb } from '@unhead/schema-org/vue';
import type { Schemas } from '#shopware';
import type { SharedOffcanvas } from '~/components/shared/SharedOffcanvas.vue';
import type { AhBreadcrumb } from '~/types/models/page';

defineOptions({
    name: 'PageResolver',
});

const nuxtApp = useNuxtApp();

const offcanvas = inject<SharedOffcanvas>('offcanvas') as SharedOffcanvas;
const { getPage, getPathWithoutLang, pageBreadcrumbs } = usePage();
const { path } = useRoute();
const { clearCmsStore } = useCmsStore();
const { isLoggedIn } = useUser();
const { isHomePath } = useSeoPath();

const { customHomepagePath } = await getCustomHomepagePath();
const { setSeoPaths, setCanonicalUrl, i18nLocaleSlugs, pathToSeoUrl } = useLanguageSelect();

// load custom homepage if it is set in user data and if home page is called.
// works both for manual address change and in-app navigation but doesn't change homepage url.
// decodeURIComponent is needed because special characters in some languages
let getPath = decodeURIComponent(path);
if (isHomePath.value && customHomepagePath) {
    getPath = customHomepagePath;
}

const cleanedPath = getPathWithoutLang(getPath);
const { data: pageResult, error } = await useAsyncData('cmsResponse' + cleanedPath, () => getPage(cleanedPath));

// redirect to url if seo_lookup page is found.
// runWithContext is needed to avoid "Nuxt instance unavailable" message.
// see: https://nuxt.com/docs/api/composables/use-nuxt-app#runwithcontext
if (pageResult.value?.apiAlias === 'seo_lookup' && pageResult.value?.url) {
    const path = pathToSeoUrl(pageResult.value?.url);
    await nuxtApp.runWithContext(() => navigateTo(path, { redirectCode: 301, replace: true }));
}

// @ts-ignore Property 'category' does not exist on type 'CmsPageResponse'
const isSecured = computed(() => !!pageResult.value?.category?.extensions?.attributes?.loginModal);
if (isSecured.value && !isLoggedIn.value) {
    offcanvas.open('AccountLoginForm', 'layout-login-offcanvas');
}

provide('page', pageResult);

await setSeoPaths(pageResult.value?.seoUrls);

const setI18nParams = useSetI18nParams();
setI18nParams({
    ...i18nLocaleSlugs.value,
});
setCanonicalUrl(pageResult.value?.canonicalPathInfo);

// autoLanguageRedirect();

const { breadcrumbs, clearBreadcrumbs } = useBreadcrumbs(pageBreadcrumbs.value);

if (breadcrumbs.value?.length) {
    useSchemaOrg([
        defineBreadcrumb({
            itemListElement: (breadcrumbs.value as AhBreadcrumb[])?.map(({ name, seoPath }) => {
                let path = seoPath || undefined;
                if (path && !path.startsWith('/')) {
                    path = removeTrailingSlash('/' + path);
                }

                return {
                    name,
                    item: path,
                };
            }),
        }),
    ]);
}

onBeforeRouteLeave(() => {
    clearBreadcrumbs();
    clearCmsStore();
});

const { routeName } = useNavigationContext(
    ref({
        routeName: pageResult.value?.resourceType,
        foreignKey: pageResult.value?.resourceIdentifier,
    }) as Ref<Schemas['SeoUrl']>,
);

function render() {
    if (error.value) {
        showError(error.value);
    } else if (isSecured.value && !isLoggedIn.value) {
        showError({ statusCode: 401 });
    }

    const componentName = routeName.value;
    if (!componentName) {
        return;
    }

    const componentNameToResolve = pascalCase(componentName as string);
    const cmsPageView = resolveComponent(componentNameToResolve);

    if (cmsPageView) {
        if (cmsPageView === componentNameToResolve)
            return h('div', {}, 'Problem resolving component: ' + componentName);
        return h(cmsPageView, { page: pageResult });
    }
    return h('div', {}, 'Loading...');
}
</script>

<template>
    <render />
</template>
